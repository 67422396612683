import React from "react"
import Layout from "../components/Layout"
import Copyright from "../components/Copyright"

const Page404 = ({ data, pageContext }) => {
  return (
    <Layout>
      <div class="wrapper" id="app">
        <header className="headerContainer" data-scroll-section>
          <div className="headerContainer__logo">
            <div className="headerContainer__logo">
              <a href="/">
                <img src="/logo.svg" alt="株式会社ブイ・フォース / vforce" />
              </a>
            </div>
          </div>
        </header>
        <section class="page-header">
          <h2>404 Not Found</h2>
        </section>
        <main class="content">
          <section class="page-body flex">
            <div class="container center">
              <p>
                Page not Found.
              </p>
              <a href="/" className="btn">
                Back Home
              </a>
            </div>
          </section>
        </main>
        <Copyright />
      </div>
    </Layout>
  )
}

export default Page404